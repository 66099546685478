.dashboard {
    padding: 30px 0;
    min-height: calc(100vh - 171px);
    background-color: #fafafa;
}

.dashboard-banner {
    margin-bottom: 20px;
}

.remark-link {
    cursor: pointer;
    /* color: #a633db;
    transition: all .3s ease; */
}

/* .remark-link:hover {
    color: #f16b2f;
} */