.header {
    padding: 10px 0;
    background-color: #ffffff;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Eina01', sans-serif;
}

.navbar-brand {
    max-width: 225px;
}

.navbar-brand img {
    width: 100%;
}

.navbar .nav-item {
    display: inline-block;
    list-style-type: none;
}

.nav-list .nav-item a {
    padding: 15px;
    color: #a633db;
    text-decoration: none;
    transition: all .4s ease;
}

.nav-list .nav-item a:hover {
    color: #f16b2f;
}

.nav-list .nav-item a.active {
    color: #f16b2f;
}